<template>
	<div>
		<v-tabs-items
			v-model="currentTab"
		>
			<v-tab-item
				v-for="item in menu"
				:key="item.id"
				transition="v-fade-transition"
			>
				<div class=content>
					<v-card
						flat
						width="1200px"
					>
					<div 
						v-if="blocks&&blocks[item.link.replace('/','')]"
						class="d-flex flex-no-wrap justify-space-between"
					>
						<div>
						<v-card-title
							class="text-h3"
						>
							{{ item.name }}
						</v-card-title>
						<v-card-text>
							<div
								class="text-h1"
							>
								{{ blocks[item.link.replace("/","")].title }}
							</div>
							<br>
							<div
								class="text-h5"
								v-html="blocks[item.link.replace('/','')].text"
							>
							</div>
						</v-card-text>
						<v-card-actions>
							<v-btn
								outlined
								:to="blocks[item.link.replace('/','')].target"
							>
								More Information
							</v-btn>
						</v-card-actions>
						</div>
					<div>
						<div 
							v-if="true"
						>
							<v-img
								class="h_image"
								:src="blocks[item.link.replace('/','')].img"
							>
							</v-img>
						</div>
					</div>
					</div>
					</v-card>
				</div>
			</v-tab-item>
		</v-tabs-items>
		<v-card>
		<v-tabs
			v-model="currentTab"
			align-with-middle
			centered
		>
			<v-tabs-slider color="grey"> </v-tabs-slider>
	
			<v-tab
				v-for="item in menu"
				:key="item.id"
				class="tab"
			>		
				{{ item.name }}
			</v-tab>
		</v-tabs>
	
		<v-tabs-items 
			v-model="currentTab"
		>
			<v-tab-item
				v-for="item in menu"
				:key="item.id"
				transition="v-fade-transition"
			>
				<div 
					class="content"
				>
				<v-card 
					flat
					width="1200px"
					height="700px"
					:color="item.color"
				>
					<v-card-title class="text-h5">
						{{ item.snippet}}
					</v-card-title>
					<v-card-text>
						<v-container
							style="height: 100%;"
						>
						<v-row>
							<v-col
								v-for="plan in item.children"
								:key="plan.id"
							>
								<v-card
									color="white"
									tile
									style="height: 600px;"
								>
									<v-card-title>
										{{ plan.name }}
									</v-card-title>
									<v-card-text
										style="height: 80%;"
										v-html="plan.msg"
									>
									</v-card-text>
									<v-card-action>
										<div class="text-center">
											<v-btn
												v-if="plan.link.indexOf('/contact')==-1"
												outlined
												:to="plan.link"
											>Buy</v-btn>
											<v-btn
												v-if="plan.link.indexOf('/contact')!=-1"
												outlined
												:to="plan.link"
											>Contact Us</v-btn>
										</div>
									</v-card-action>
								</v-card>
							</v-col>
						</v-row>
						</v-container>
					</v-card-text>
				</v-card>
				</div>
			</v-tab-item>
		</v-tabs-items>

		</v-card>
	</div>
</template>

<script>
module.exports = {
	data() {
		return {
			currentTab: null,
			greeting: "Hello",
			menu: {},
			blocks: {}
		};
	},
	methods: {
		parseMenu(response, m) {
			var menu = response.data.menuList;
			var newMenu = {}
			var rootID=0;
		
			// find the root ID
			for(const i in menu) {
				menu[i].children = []
				menu[i].handle = menu[i].link.replace("/","")
				newMenu[menu[i]["id"]] = menu[i];
				if(menu[i].parent==-1) {
					rootID = menu[i].id
				}
			}

			// build tree
			for(const id in newMenu) {
				var pid = newMenu[id].parent
				if(pid!=-1) {
					try {
						newMenu[pid].children.push(newMenu[id])
					} catch(e) {
						console.log("pid " + pid + " is not defined");
					}
				}
			}

			// set icon based on number of children.
			// also, remove url link if we have children
			for(const id in newMenu) {
				if(newMenu[id].children.length>0) {
					newMenu[id].action = this.downIcon;
					newMenu[id]["route"] = newMenu[id].link;
				} else if(newMenu[id].snippet.length>0) {
					newMenu[id].action = this.downIcon;
					newMenu[id]["route"] = newMenu[id].link;
				} else {
					newMenu[id].action = this.defaultIcon;
				}

				try {
					newMenu[id]["menuID"] = this.menuRoute[newMenu[id].route]
				} catch(e) {
					newMenu[id]["menuID"] = null
				}
			}

			for(var sm in newMenu[rootID].children) {
				newMenu[rootID].children[sm].children = newMenu[rootID].children[sm].children.sort(function(a,b) {
					return(a.sort_order>b.sort_order)
				})
			}

			// Sort Menu by sort_order
			this[m] = newMenu[rootID].children.sort(function(a,b) {
				return(a.sort_order>b.sort_order)
			});

			console.log(JSON.stringify(this[m], null, "\t"))
		},

	},
	mounted() {
		var p = this.$route.path
		var pp = p.split("/")
		var n = pp[pp.length-2] 

		console.log("menu id is " + n);

		this.$http.get("/svc/menu/"+n+"/").then(response => {
			this.parseMenu(response, "menu");
			for(var m in this.menu) {
				// generate html for each option
				for(var c in this.menu[m].children) {
					var ci;
					try {
						ci = JSON.parse(this.menu[m].children[c].snippet2)
						var per_user = parseInt(ci["per_user"])
						var setup = parseInt(ci["setup"])
						var monthly = '<span style="font-size: xx-large;">'
						var onetime = ""

						if(per_user>0) {
							monthly += "$" + per_user + ".00</span><br>per user per month<br>"
						} else {
							monthly += "Free</span><br>"
						}

						if(setup>0) {
							onetime += "$" + setup + " Setup<br><br>"
						} else {
							onetime += "<br>"
						}

						this.menu[m].children[c].msg = monthly + onetime + this.menu[m].children[c].snippet;

					} catch(e) {
						ci = {"per_user": -1,"setup":-1}
						this.menu[m].children[c].msg = this.menu[m].children[c].snippet
					} 
					console.log("Cost: " + JSON.stringify(ci))
				}

				var key = this.menu[m].link.replace("/","");
				console.log("getting blocks for " + key)			

				// Get blocks associate with each menu item
				this.$http.get("/svc/page"+this.menu[m].link).then(response => {
					var key = response.data.route
					if(response.data.pageBlocks) {
						console.log("saving block as " + key)			
						this.blocks[key] = response.data.pageBlocks[0];

						if(response.data.pageBlocks[0].links) {
							this.blocks[key]["img"] = response.data.pageBlocks[0].links[0].link
							this.blocks[key]["target"] = response.data.pageBlocks[0].links[0].target
						}

						console.log(JSON.stringify(this.blocks[key]))
						this.$forceUpdate();
					} else {
						this.blocks[key] = {"title":"","text":"","img":"", "target":""};
					}
				})
			}
		})

	}
}
	
</script>

<style scoped>
p {
	font-size: 2em;
	text-align: center;
}

.tab {
	width: 200px;
}

.content {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.header {
	display: flex;
	justify-content: center;
	height: 400px;
}

.h_image {
	width: 400px;
	border-radius: 200px;
}
</style>
